.servicesss {
    color: rgba(10, 60, 100, 0.979);
    text-align: center;
}

.servicesss-containers {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px; 
    margin-top: 40px;
    padding-left: 150px;
}

/* For mobile devices */
@media (max-width: 480px) {
    .servicesss-containers {
        grid-template-columns: repeat(1, 1fr);
        gap: 50px;
        margin-top: 40px;
        align-items: center;
        text-align: center;justify-items: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
    /* Optional: If you want to center the entire grid within its parent */
    justify-content: center; 
        // margin-left: 7rem;
    }
}

.servicesss-card {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white; /* Adjust background color if needed */
    border: 2px solid rgba(80, 0, 80, 0.979);
    border-radius: 40px; /* Optional border-radius */
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
    width: 60%;
    height: 100%;
    @media (max-width: 480px) {
        .servicesss-card {
            display: flex;
            align-items: center;
            justify-content: center;
            justify-items: center;
            background: white; /* Adjust background color if needed */
            border: 2px solid rgba(80, 0, 80, 0.979);
            border-radius: 40px; /* Optional border-radius */
            overflow: hidden;
            cursor: pointer;
            transition: transform 0.3s, box-shadow 0.3s;
            width: 100%;
            height: 100%;}
        
    }
    /* For tablets (landscape) */
    @media (max-width: 1024px) {
        grid-template-columns: repeat(2, 1fr); /* 2 cards per row */
        gap: 15px;
    }

    /* For tablets */
    @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr); 
        gap: 15px;
    }

    &:hover {
        // transform: scale(1.2);
        box-shadow: 0 16px 8px rgba(80, 0, 80, 0.979); /* Added space */
    }
    
    @media (max-width: 480px) {
        // transform: scale(1.05);
        width: 80%;
    }
}

.floating-image {
    width: 100%;
    height: 80%;
    
    @media (max-width: 480px) {
        width: 100%;
    }
}

.ss {
    text-align: center;
    color: rgba(80, 0, 80, 0.979);
    font-weight: lighter;
    font-size: 1.7rem;
    
    @media (max-width: 480px) {
        font-size: 0.5rem;
    }
}

.pp {
    text-align: center;
    color: rgba(80, 0, 80, 0.979);
    font-weight: lighter;
    font-size: 1.5rem;
    
    @media (max-width: 480px) {
        font-size: 0.5rem;
    }
}

.h4 {
    text-align: start;

}





@media (max-width: 768px) {

 
  
}

/* Mobile (max-width: 480px) */
@media (max-width: 480px) {
    .hsss{
    font-size: 1rem;
    }
   .testimonial-carousel-top-port img {
    object-fit: cover;
    width: 400px;
    height: 200px;
   }

}
