.footer {
  color: #ffffff;
  margin-top: 60px;
  background-color: rgba(60, 0, 60, 0.979);

  .footer-container {
    background-color: inherit; 
    .footer-row {
      display: flex; // Arrange columns in a row
      justify-content: space-between; // Distribute columns evenly
      flex-wrap: wrap;
      width: 100%;
      height: auto;
      background-color: inherit; 
 


    

      .footer-column {
        flex: 1; // Ensure columns have a minimum width
        margin: 2px; // Space between columns

        h3 {
         justify-content: center;
         text-align: start;
      margin-top: 3.8rem;
          font-size: 15px;
          color: white;
        }

        p, a {
          color: white;
          font-size: 15px;
          text-decoration: none;
          text-align: justify;
          margin-top: 28px;

          &:hover {
            color:rgba(10, 60, 100, 0.979);
          }
        }
        .map-container {
          margin-top: 10px;
        }
        
        .map-container iframe {
          border: 0;
        }
     
        .social-icons {
          display: flex;
          flex-wrap: wrap;
          gap: 4rem;
          margin-top: 28px;

          a {
         
            font-size: 16rem; // Social icons size
            color: #ffffff;
            margin-right: 8px; // Space between icons
          

            &:hover {
              color: #dcdcdc;
            }
          }
        }
      }
    }
  }

  .footer-bottom {
    text-align: center;
    margin-top: 20px;
    border-top: 1px solid #ffffff;
    padding-top: 10px;
    font-size: 14px;
    background-color: inherit; // Inherit background color from .footer

    p {
      margin: 0;
      font-size: large;
      color: white;
    }
  }

  // Tablet view adjustments
  @media (max-width: 768px) {
    .footer-row {
      flex-direction: column; // Stack columns on top of each other
      align-items: center; // Center align the columns
    }

    .footer-column {
      width: 80%; // Make each column take full width
      margin: 5px 0; // Adjust margin for spacing between stacked columns

      h3 {
        font-size: 16px; // Adjust font size for h3
      }

      p, a {
        font-size: 14px; // Adjust font size for p and a elements
        margin-top: 15px; // Adjust top margin for spacing
      }

      .social-icons a {
        font-size: 2.5rem; // Adjust icon size for tablet view
        margin-right: 6px; // Adjust space between icons
      }
    }

    .footer-bottom {
      font-size: 12px; // Adjust font size for footer-bottom
    }
  }

  // Mobile view adjustments
  @media (max-width: 480px) {
    .footer-row {
      flex-direction: column; // Stack columns vertically
      align-items: center; // Center align the columns
    }

    .footer-column {
      width: 100%;
      margin: 5px 0; // Adjust margin for stacked columns

      h3 {
        font-size: 14px; // Smaller font size for h3 on mobile
        text-align: center;
      }

      p, a {
        font-size: 13px; // Smaller font size for p and a on mobile
        margin-top: 10px; // Adjust top margin for spacing
      }

      .social-icons a {
        font-size: 2rem; // Smaller icon size on mobile
        margin-right: 4px; // Less space between icons on mobile
      }
    }

    .footer-bottom {
      font-size: 10px; // Adjust font size for footer-bottom on mobile
    }
  }
}
