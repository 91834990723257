:root {
  --color-primary: #000000; /* Black */
  --color-secondary: rgba(10, 60, 100, 0.979); /* Dark Skyblue */
  --color-tertiary: rgba(80, 0, 80, 0.979); /* Dark Purple */
}
body {
  overflow-x: hidden;
}
.App-header {
  background: white;
  position: sticky; /* Fixes the header to the top of the viewport */
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* box-shadow: 0 5px 25px rgba(87, 84, 84, 0.25); */
  z-index: 1000;
}

.header-left {
  display: flex;
  align-items: center;
  position: relative;
  background-color: white;
}

/* .header-left::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px; /* Adjust the width of the circle */
/* height: 100px; /* Adjust the height of the circle */
/* background: #ffffff;
  border-radius: none;
  z-index: -1; /* Ensure the circle is behind the logo */
/* box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
} */

.App-logo {
  height: 100px;
  width: 250px;
  margin-right: 1rem; /* Center the logo inside the circle */
  /* transition: transform 2s ease-out; */
  z-index: 8; /* Ensure the logo stays on top */
}

@media (max-width: 768px) {
  .App-logo {
    height: 80px; /* Adjust height for tablets */
    width: 80px; /* Adjust width for tablets */
  }
}

/* Mobile (max-width: 480px) */
@media (max-width: 480px) {
  .App-logo {
    height: 60px; /* Adjust height for mobile */
    width: 60px; /* Adjust width for mobile */
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.menu-gif {
  width: 70px;
  height: auto;
  vertical-align: middle;
}

/* Tablet (max-width: 768px) */
@media (max-width: 768px) {
  .menu-gif {
    width: 50px; /* Adjust width for tablets */
  }
}

/* Mobile (max-width: 480px) */
@media (max-width: 480px) {
  .menu-gif {
    width: 30px; /* Adjust width for mobile */
  }
}

.header-text {
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
}

.App-heading {
  font-size: 2rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.4rem;
  margin: 0;
}
.menu-button {
  display: none; /* Hide the menu button on desktop */
}

.App-nav {
  display: flex;
  position: relative;
  gap: 70px;
  align-items: start;
  margin-left: 0;
  padding-bottom: 10px; /* Add space for marker */
}

.App-nav ul {
  padding: 0;
  list-style: none;
  display: flex;
  font-size: 2rem;
  justify-content: flex-end; /* Aligns the navigation to the right */
  width: 100%; /* Ensure the ul takes the full width of the container */
}

ul li {
  list-style: none;
  position: relative; /* For positioning the pseudo-elements */
}

ul li a {
  position: relative;
  text-decoration: none;
  display: flex;
  align-items: end;
  font-size: 2rem;
  padding: 20px 30px;
  z-index: 1; /* Ensure it's above the pseudo-elements */
  backdrop-filter: blur(15px);
  transition: font-size 0.5s ease-in-out; /* Smooth transition for font-size */
  color: black; /* Text color */
}

ul li a::before,
ul li a::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth transitions for opacity and transform */
}

ul li a::before {
  top: 0; /* Position the line above the text */
  transform: scaleX(0); /* Initially hidden */
}

ul li a::after {
  bottom: 0; /* Position the line below the text */
  transform: scaleX(0); /* Initially hidden */
}

ul li a:hover::before,
ul li a:hover::after {
  transform: scaleX(1); /* Show the line */
  background: black;
}

ul li a:hover {
  font-size: 2.2rem; /* Slightly increase font size on hover */
}

ul li.active a::before,
ul li.active a::after {
  opacity: 1; /* Ensure lines are visible when the item is active */
  transform: scaleX(1); /* Ensure lines are fully visible when active */
}

.custom-heading1 {
  color: black;
  font-weight: lighter;
  font-size: 1.3rem;
}

.custom-heading2 {
  color: black;
  font-size: 1.3rem;
  font-weight: lighter;
}


/* Styling for the sticky contact bubble icons */
.contact-icons {
  position: fixed;
  top: 50%;  
  right: 15px;  /* Place 20px from the right edge */
  transform: translateY(-380%);  /* Adjust to exactly center */
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact-icons a {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #007bff;  /* Blue color */
  width: 60px;
  height: 60px;
  border-radius: 50%;  /* This makes it a bubble */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.8s ease-in-out;
}

.contact-icons a:hover {
  transform: scale(1.3);  /* Scale effect on hover */
}

.contact-icons i {
  color: white;
  font-size: 2rem;
}


.banner-container {
  position: relative;
  width: 100%;
  height: 90vh;
  overflow: hidden;
  padding: 0rem;
  margin-top: 1rem;
}

.banner {
  background-size: cover; /* Ensures the image covers the entire container */
  background-position: center; /* Centers the image within the container */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  height: 100%; /* Adjust based on your design needs */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-content {
  text-align: center;
  max-width: 100%;
  background-color: rgba(0, 0, 0, 0);
  margin: 0%;
}
.banner-content h2,
.banner-content button {
  font-weight: lighter;
  color: white;
  font-size: 1.2rem;
}
.banner-content h1 {
  color: white;
  text-align: start;
}

.banner-content button {
  background-color: rgba(
    80,
    0,
    80,
    0.979
  ); /* Primary button color, adjust as needed */
  border: none;
  color: white;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: 10px; /* Space above the button */
}

.banner-content button:hover {
  background-color: v; /* Darker shade for hover effect */
  transform: scale(1.05); /* Slightly enlarges the button on hover */
}

.banner-content button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5); /* Adds focus ring */
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px); /* Adjust the float height */
  }
}

.menu-button {
  display: none; /* Hide the menu button on desktop */
}

.vs-process-container {
  text-align: center;
  padding: 2rem;
  margin: 0;
}

.vs-process-heading {
  margin-bottom: 2rem;
  color: rgba(80, 0, 80, 0.979);
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
}

.vs-process-row {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: nowrap;
}

.vs-process-item {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center;
  position: relative; /* For positioning the arrow */
}

.vs-process-circle {
  width: 80px; /* Adjust size as needed */
  height: 80px; /* Adjust size as needed */
  border-radius: 50%;
  background-color: #fff; /* Background color of the circle */
  border: 2px solid #ddd; /* Border color of the circle */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem; /* Space between circle and name */
}

.vs-process-image {
  margin-top: 2.5rem;
  width: 130px; /* Adjust size as needed */
  height: 130px; /* Adjust size as needed */
  border-radius: 50%;
  animation: zoomIn 30s linear infinite;
}

.vs-process-arrow {
  font-size: 2rem; /* Adjust size as needed */
  color: rgba(10, 60, 100, 0.979);
  position: absolute;
  right: -2rem; /* Adjust position */
  top: 50%;
  transform: translateY(-50%);
  z-index: 1; /* Ensure it appears above other content */
}

.vs-process-item:not(:last-child) .vs-process-arrow {
  display: inline-block;
}

.vs-process-name {
  margin-top: 2.5rem;
  color: rgba(80, 0, 80, 0.979);
}

.vs-process-name p {
  margin: 0;
  padding: 0;
  color: black;
}

@keyframes zoomIn {
  from {
    transform: scale(0.5);
    opacity: 1;
  }
  to {
    transform: scale(1.2);
    opacity: 1;
  }
}

/* Mobile View Adjustments */
@media (max-width: 480px) {
  .App-nav {
    display: none; /* Hide the navigation bar */
  }

  .App-nav.show {
    display: block; /* Shown when the 'show' class is added */
  }

  .menu-button {
    display: block; /* Show the menu button on mobile view */
    background: transparent;
    border: none;
    color: white;
    font-size: 2rem;
    padding: 10px;
    cursor: pointer;
  }

  .App-nav.show {
    display: block;
  }

  /* Button Styles */
  .menu-button {
    display: flex;
    align-items: baseline;
    background: transparent;
    text-align: start;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
  }
  .App-nav {
    width: 100%;
    top: auto;
    bottom: 0;
  }

  .App-nav ul {
    flex-direction: column;
  }

  .App-nav li {
    border-bottom: none; /* Remove border for mobile view */
  }

  .App-nav li a {
    padding: 0px; /* Adjust padding for mobile view */
  }
  .App-nav {
    width: 100%; /* Full width on mobile */
  }

  .App-nav li {
    margin: 15px 0; /* Adjust margin for mobile */
  }

  .vs-process-row {
    flex-direction: column; /* Stack items vertically */
    align-items: center;
  }

  .vs-process-item {
    margin-bottom: 1rem; /* Add some space between items */
  }

  .vs-process-arrow {
    display: none; /* Hide arrows on mobile for simpler design */
  }

  .vs-process-image {
    width: 100px; /* Adjust image size for mobile */
    height: 100px;
  }

  .vs-process-circle {
    width: 70px; /* Adjust circle size for mobile */
    height: 70px;
  }

  .vs-process-name {
    font-size: 1.2rem; /* Adjust text size for mobile */
  }
  .banner {
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: start;
  }
  .banner-container {
    position: relative;
    width: 100%;
    height: 40vh;
    overflow: hidden;
    padding: 0rem;
    margin-top: 1rem;
  }
}

.banner-content h3 {
  color: #fff !important;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 250px;

  height: 250px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Styling for dropdown links */
.dropdown-content a {
  color: rgba(80, 0, 80, 0.979);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 0.9rem;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: white;
}

/* Show the dropdown content when hovering over the dropdown container */
.dropdown:hover .dropdown-content {
  display: block;
}

.h3 {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .banner-content h2,
  .banner-content button {
    text-align: center;
    font-size: 1rem; /* Adjust font size for tablets */
  }

  .banner-content h1 {
    text-align: center;
    font-size: 1.5rem; /* Adjust font size for tablets */
  }
  .custom-heading1 .custom-heading2 {
    font-size: 1.1rem; /* Adjust font size for tablet */
  }
  .custom-heading2 {
    font-size: 0.8rem; /* Adjust font size for tablet */
  }
}

/* Mobile (max-width: 480px) */
@media (max-width: 480px) {
  .banner-content h3 {
    text-align: center !important;
    margin-top: 10px;
    font-size: 0.9rem; /* Adjust font size for mobile */
  }

  .banner-content h2,
  .banner-content button {
    text-align: center;
    margin-top: 10px;
    font-size: 0.7rem; /* Adjust font size for mobile */
  }

  .banner-content h1 {
    text-align: center;
    margin-top: 10px;
    font-size: 0.9rem; /* Adjust font size for mobile */
  }
  .banner-content button {
    font-size: 10px;
    padding: 10px;
  }
  .custom-heading1 {
    font-size: 0.8rem; /* Adjust font size for tablet */
  }
  .custom-heading2 {
    font-size: 0.8rem; /* Adjust font size for tablet */
  }
  .dropdown-content {
    width: 100%;
    min-width: 90px;
    height: 100px;
    z-index: 999;
  }
  .dropdown-content a {
    padding: 5px;
    font-size: 0.7rem;
  }
}
