

.form-container {
  margin: 0%;

  }
  
  form {
    width: 100%;
    max-width: 600px; /* Adjust as needed */
    background: rgba(80, 0, 80, 0.979);
    padding: 20px;
    border-radius: 8px;
    box-shadow: white;
  
   
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
    color: white;
  }
  
  label {
    display: block;
    margin-bottom: 15px;
    color: white;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="number"],
  textarea,
  select {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid white;
    border-radius: 4px;
    gap: 10px;
  }
  
  bt {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: rgba(10, 60, 100, 0.979);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  bt:hover {
    background-color: rgba(10, 60, 100, 0.979);
  }
  .skills-dropdown {
    position: relative;
    width: 100%;
    cursor: pointer;
    border: 1px solid #ccc;
    padding: 10px;
    background-color: #fff;
  }
  
  .skills-dropdown-header {
    font-size: 16px;
    color: black;
  }
  
  .skills-dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid white;
    background-color: rgba(10, 60, 100, 0.979);;
    z-index: 1000;
  }
  
  .skills-dropdown-item {
    padding: 10px;
    border-bottom: 1px solid white;
  }
  
  .skills-dropdown-item input {
    margin-right: 10px;
  }
  
  .skills-dropdown-item:hover {
    background-color: #f5f5f5;
  }
  .bttt {
    padding: 0.6rem 1.3rem;
    background-color: #fff;
    border: 2px solid #fafafa;
    font-size: 0.95rem;
    color: rgba(10, 60, 100, 0.979);
    line-height: 1;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    margin: 0;
    width: 100%;
  }
  
  .bttt:hover {
    background-color: transparent;
    color: rgba(10, 60, 100, 0.979);
  }
  
  .bttt:active {
    background-color: rgba(10, 60, 100, 0.979);
    color: #fff;
  }

 /* Career.css */
.form-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto; /* Allow scrolling if content overflows */
  z-index: 1000;
}

.form-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.form-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  max-height: 90vh; /* Ensure form doesn't exceed viewport height */
  overflow-y: auto; /* Allow vertical scrolling */
  position: relative;
  z-index: 1001;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(10, 60, 100, 0.979);
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 30px;
  cursor: pointer;
}
