.service-sections {
  display: flex;
  width: 100%;
  height: 100vh;
 /* Optional: makes the section fill the viewport height */
}

.sidebarss {
  width: 25%;
  background-color: rgba(80, 0, 80, 0.979); /* Dark Purple */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  color: white;
  height: 150%;
}

.main-content {
  width: 75%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start; /* Changed from 'start' to 'flex-start' */
}

.sideul {
  list-style-type: none;
  padding: 0;
  align-items: start; 
  text-align: start;
  display: flex;
  flex-direction: column;
  background-color: rgba(80, 0, 80, 0.979); /* Dark Purple */
  font-size: 1.4rem;
margin-top: 4rem;

}

.sideul li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
 
  align-items: start; 
  
  text-align: start;
}

.sideul li.active {
  background-color: rgba(10, 60, 100, 0.979);
  font-weight: bold;
  text-align: right;
}

.package-details {
  display: none;
  text-align: start;
}

.package-details.active {
  display: block;
}

.package-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.service-image {
  width: 60%;
  height: auto;
  margin-bottom: 10px;
  animation: slideInLeft 3s ease-in-out forwards; /* Animation for image */
}

.package-price {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.details-list {
  list-style-type: none;
  padding: 0;
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  align-items: start; 
  animation: fadeInUp 0.5s ease-in-out forwards; /* Animation for each detail item */
  animation-delay: calc(var(--delay, 0) * 2s); /* Delays each item */
  text-align: start;
  gap: 5px; /* Adjust gap as needed */
}

.detail-item {
  display: flex; /* Use flex to align icon and text */
  align-items: start; /* Center vertically */
  animation: fadeInUp 0.5s ease-in-out forwards; /* Animation for each detail item */
  animation-delay: calc(var(--delay, 0) * 2s); /* Delays each item */
  text-align: start;
}

.icon {
  color: red; 
  margin-right: 0px;
}

.h {
  text-align: start;
  margin-top: 5.5rem;
  color: white;
}

div.backBtn {
  position:absolute; /* Fix the button to the viewport */
left: 2rem;
margin-top: 1rem;
  transform: translateY(-50%); 
  width: 100px;
  background-color: #f4f4f4;
  transition: all 0.4s ease;
  cursor: pointer;

  color: white;
}

span.line {
  bottom: auto;
  right: auto;
  top: auto;
  left: auto;
  background-color: white;
  border-radius: 10px;
  width: 100%;
  left: 0px;
  height: 2px;
  display: block;
  position: absolute;
  transition: width 0.2s ease 0.1s, left 0.2s ease, transform 0.2s ease 0.3s, background-color 0.2s ease;
}

span.tLine {
  top: 0px;
}

span.mLine {
  top: 13px;
  opacity: 0;
}

span.bLine {
  top: 26px;
}

.label {
  position: absolute;
  left: 0px;
  top: 5px;
  width: 100%;
  text-align: center;
  transition: all 0.4s ease;
  font-size: 1em;
}

div.backBtn:hover span.label {
  left: 25px;
}

div.backBtn:hover span.line {
  left: -10px;
  height: 5px;
  background-color: white;
}

div.backBtn:hover span.tLine {
  width: 25px;
  transform: rotate(-45deg);
  left: -15px;
  top: 6px;
}

div.backBtn:hover span.mLine {
  opacity: 1;
  width: 30px;
}

div.backBtn:hover span.bLine {
  width: 25px;
  transform: rotate(45deg);
  left: -15px;
  top: 20px;
}
@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}


/* Existing Code */

.details-list .detail-item:nth-child(1) {
  --delay: 1;
}

.details-list .detail-item:nth-child(2) {
  --delay: 2;
}

.details-list .detail-item:nth-child(3) {
  --delay: 3;
}

.details-list .detail-item:nth-child(4) {
  --delay: 4;
}

.details-list .detail-item:nth-child(5) {
  --delay: 5;
}

/* Add more delay for items beyond the 5th one */

.details-list .detail-item:nth-child(6) {
  --delay: 6;
}

.details-list .detail-item:nth-child(7) {
  --delay: 7;
}

.details-list .detail-item:nth-child(8) {
  --delay: 8;
}

.details-list .detail-item:nth-child(9) {
  --delay: 9;
}

.details-list .detail-item:nth-child(10) {
  --delay: 10;
}

.details-list .detail-item:nth-child(11) {
  --delay: 11;
}

.details-list .detail-item:nth-child(12) {
  --delay: 12;
}

.details-list .detail-item:nth-child(13) {
  --delay: 13;
}

.details-list .detail-item:nth-child(14) {
  --delay: 14;
}

.details-list .detail-item:nth-child(15) {
  --delay: 15;
}


/* Media Queries for Mobile and Tablet Responsiveness */
@media screen and (max-width: 768px) {
  .service-section {
    flex-direction: column;
  }

  .sidebarss {
    width: 100%;
    height: auto; /* Adjust height as needed */
    box-shadow: none;
  }

  .main-content {
    width: 100%;
    padding: 15px;
  }

  .sideul {
    margin-top: 1rem;
    font-size: 1.2rem;
  }

  .sideul li {
    padding: 8px;
    font-size: 1.1rem;
  }

  .package-title {
    font-size: 20px;
  }

  .service-image {
    width: 100%;
    height: auto;
  }

  .package-price {
    font-size: 18px;
  }

  .details-list {
    font-size: 0.8rem;
  }

  div.backBtn {
    top: 1rem;
    left: 1rem;
    width: 80px;
  }

  .label {
    font-size: 0.9em;
  }
}

@media screen and (max-width: 480px) {
  .sidebarss {
    width: 100%;
    height: auto;
  }

  .sideul {
    margin-top: 1rem;
    font-size: 1rem;
  }

  .sideul li {
    padding: 8px;
    font-size: 1rem;
   
    font-style:oblique;
  }

  .package-title {
    font-size: 18px;
  }

  .service-image {
    width: 100%;
    height: auto;
  }

  .package-price {
    font-size: 16px;
  }

  .details-list {
   
    font-size: 1.4rem;
    font-weight: 500;
    color: black;
    font-style:oblique;
  }

  div.backBtn {
    top: 1rem;
    left: 1rem;
    width: 70px;
  }

  .label {
    font-size: 0.8em;
  }}

.default-banner{
  background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    display: flex;
  

}

.banner-heading{
  text-align: center;
}

.banner-subheading{
  text-align: start;
}

.contact-button {
  padding: 10px 20px;
  background-color: rgba(80, 0, 80, 0.979);;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  margin-left: 4rem;
  transition: background-color 0.3s;
}

.contact-button:hover {
  background-color: #0056b3;
}


@media (max-width: 768px) {

 
  
}

/* Mobile (max-width: 480px) */
@media (max-width: 480px) {
.detail-item {
  font-size: 10px;
}
.contact-button {
  margin: 5px;
  font-size: 10px;
}
.sideul {
  margin-top: 3rem;
}

}

