.native-app-development-page {
    padding: 20px;
  }
  
  .content-and-image {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 40px;
    background:black;
    color: white;
    height: 80%;
}
.content-and-images {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 3rem;
  margin-bottom: 40px;
  background:  white;
  color: black;
}
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  
  .image img {
    width: 100%;
    height: 100%;
  }
  

  

  
 
  
  .card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 20px;
  }
  
  .cards {
    position: relative;
    background-color: #f8f9fa;
 padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden; /* Ensures the pseudo-element doesn't overflow the card */
    z-index: 1;
  }
  
  .cards::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color:rgba(80, 0, 80, 0.979);
    z-index: -1;
    transition: width 2s ease; /* Controls the speed and smoothness of the fill */
  }
  
  .cards:hover::before {
    width: 100%;
  }
  .cards h3{
    position: relative;
    z-index: 1;
    margin: 0;
    color:rgba(80, 0, 80, 0.979);
    transition: color 0.6s ease; 
  }
  .cards ul li {
    position: relative;
    z-index: 1;
    color:rgba(10, 60, 100, 0.979);
    transition: color 0.6s ease;
  }
  
  .cards:hover h3,
  .cards:hover ul li {
    color: white;
  }
  
  .cards ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .cards ul li {
    margin-bottom: 10px;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  
 
  
  .natives {
    padding: 10px;
    margin-top: 0.6rem;
    background-color: #f0f0f0;
    background:white;
    color: white;
    border: 1px solid #ccc;
    cursor: pointer;
    width: 14rem;
    margin-left: 8rem;
    height: 4rem;
    color:black;
    border-radius: 40px;
    margin-top: 2rem;
    font-size: 2rem;
    margin-bottom: 1rem;
    transition: background-color 3s ease;
}

.nativess {
  padding: 10px;
  margin-top: 0.6rem;
  background-color: #f0f0f0;
  background:black;
  color: white;
  border: 1px solid #ccc;
  cursor: pointer;
  width: 14rem;
  margin-left: 8rem;
  height: 4rem;
  color:white;
  border-radius: 40px;
  margin-top: 2rem;
  font-size: 2rem;
  transition: background-color 3s ease;
}
.natives:hover {
  background-color: rgba(80, 0, 80, 0.979); 
  color: white; 
}


.nativess:hover {
  background-color: rgba(80, 0, 80, 0.979); 
  color: white;
}

.hr{
  color: white;
  font-size: 2rem;
  text-align: center;
  margin-top: 2rem;
}

.pr{
  color: white;
  font-size: 1.4rem;
  text-align: start;
  margin-top: 1rem; 
}

.hrs{
  color:rgba(80, 0, 80, 0.979);
  font-size: 1.5rem;
  text-align: center;
  margin-top: 2rem;
}

.prs{
  color: black;
  font-size: 1.2rem;
  text-align: start;
  margin-top: 1rem; 
}

.lis{
  color:rgba(8, 80, 139, 0.979);
  font-size: 1rem;
  text-align: start;
  margin-top: 1rem;  
}
.red-tick {
  color: red;
  margin-right: 10px;
}


.back-button {
  position: fixed;
  bottom: 20px;
right: 20px;
width: 12rem;
height: 4rem;
  background-color:black;
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  font-size: 26px;
  color:white;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  gap: 8px;
}

.back-button:hover {
  background-color: rgba(80, 0, 80, 0.979);
  color: white;
}

.back-button svg {
  font-size: 40px;
}
@media (max-width: 768px) {
  .content-and-image,
  .content-and-images {
    grid-template-columns: 1fr; 
    margin-top: 2rem;
  }
  .card-grid {
    grid-template-columns: 1fr; 
  }
  .natives,
  .nativess {
    width: 50%;
    max-width: 90%;
    font-size: 1.5rem; 
    margin-left: 5rem;

}
.hr {
  font-size: 1.2rem; /* Adjust size for smaller screens */
}

.hrs {
  font-size: 1.3rem;
}

.prs {
  font-size: 1.5rem;
}

.lis {
  font-size: 1.2rem;
}
.back-button {
  width: 10rem; 
  height: 3rem;
  font-size: 20px;
  bottom: 15px; 
  right: 15px;
}

.back-button svg {
  font-size: 24px; 
}}