/* Main container for the cards */
.card-containers {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Individual card container styles */
.cardsss {
  flex-basis: 48%; /* Card takes up about 48% of the container */
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row; /* Align content side by side */
}

/* Card styles */
.card {
  background-color: #f0f0f0;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 270px;
  display: flex;
  flex-direction: row; /* Align image and content side by side */
  text-decoration: none; /* Remove text decoration for links */
}

.card-content {
  flex-basis: 50%; /* Image section takes 50% */
  background-size: cover;
  background-position: center;
  border-radius: 10px 10px 30px 10px; /* Round left side corners */
}

.card-info {
  flex-basis: 50%; /* Content section takes 50% */
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.cls {
  font-size: 30px;
  margin-bottom: 10px;
}

.card-description {
  font-size: 16px;
  color: #555;
}

.read-more {
  font-size: 16px;
  font-weight:bold;
  color: #007bff;
  text-decoration: none;
  margin-top: 10px;
}

.read-more:hover{
  color: #4477ad;
  font-size: 18px;
}

/* Specific background images for each card */
.card1 .card-content {
  background-image: url('./images/service1.jpg');
}

.card2 .card-content {
  background-image: url('./images/service2.jpg');
}

.card3 .card-content {
  background-image: url('./images/service3.jpg');
}

.card4 .card-content {
  background-image: url('./images/service4.jpg');
}

.card5 .card-content {
  background-image: url('./images/service5.jpg');
}

/* Responsive design for tablets */
@media (max-width: 768px) {
  .card-containers {
    grid-template-columns: 1fr; /* Stack cards in one column */
  }

  .card {
    flex-direction: row; /* Stack the image and content vertically */
    height: auto; /* Allow height to adjust based on content */
  }

  .card-content {
    height: 200px; /* Set a fixed height for the image */
  }

  .card-info {
    padding: 10px;
  }
}

/* Responsive design for mobile */
@media (max-width: 480px) {
  .card-containers {
    grid-template-columns: 1fr; /* Stack cards in one column */
  }

  .card {
    flex-direction: column; /* Stack the image and content vertically */
    height: auto; /* Adjust height based on content */
  }

  .card-content {
    height: 150px; /* Adjust image height for small screens */
  }

  .card-info {
    padding: 10px;
  }
}
