* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
input,
textarea {
  font-family: "Poppins", sans-serif;
}

.containers {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 2rem;
  background-color: #fafafa;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  width: 100%;
  max-width: 820px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.contact-form {
  background-color: rgba(80, 0, 80, 0.979);
  position: relative;
}

.contact-form:before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  background-color: rgba(80, 0, 80, 0.979);
  transform: rotate(45deg);
  top: 50px;
  left: -13px;
}

form {
  padding: 2.3rem 2.2rem;
  z-index: 10;
  overflow: hidden;
  position: relative;
}

.title {
  color: #fff;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 0.7rem;
}

.input-container {
  position: relative;
  margin: 1rem 0;
}

.input {
  width: 100%;
  outline: none;
  border: 2px solid #fafafa;
  background: none;
  padding: 0.6rem 1.2rem;
  color: white;
  font-weight: 500;
  font-size: 0.95rem;
  letter-spacing: 0.5px;
  border-radius: 5px;
  transition: 0.3s;
}
.input {
  width: 100%;
  outline: none;
  border: 2px solid #fafafa;
  background: none;
  padding: 0.6rem 1.2rem;
  color: black;
  font-weight: 500;
  font-size: 0.95rem;
  letter-spacing: 0.5px;
  border-radius: 5px;
  transition: 0.3s;
}

textarea.input {
  padding: 0.8rem 1.2rem;
  min-height: 150px;
  border-radius: 5px;
  resize: none;
  overflow-y: auto;
}

.input-container label {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  padding: 0 0.4rem;
  color: #fafafa;
  font-size: 0.9rem;
  font-weight: 400;
  pointer-events: none;
  z-index: 1000;
  transition: 0.5s;
}

.input-container.textarea label {
  top: 1rem;
  transform: translateY(0);
}
.button-container {
  display: flex;
  gap: 10px;
}

.btn {
  padding: 0.6rem 1.3rem;
  background-color: #fff;
  border: 2px solid #fafafa;
  font-size: 0.95rem;
  gap: 7px;
  color: rgba(10, 60, 100, 0.979);
  line-height: 1;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin: 0;
  width: 100%;
}

.btn:hover {
  background-color: rgba(10, 60, 100, 0.979);
  color: rgba(10, 60, 100, 0.979);
}

.input-container span {
  position: absolute;
  top: 0;
  left: 25px;
  transform: translateY(-50%);
  font-size: 0.8rem;
  padding: 0 0.4rem;
  color: transparent;
  pointer-events: none;
  z-index: 500;
}

.input-container span:before,
.input-container span:after {
  content: "";
  position: absolute;
  width: 10%;
  opacity: 0;
  transition: 0.3s;
  height: 5px;
  background-color: rgba(10, 60, 100, 0.979);
  top: 50%;
  transform: translateY(-50%);
}

.input-container span:before {
  left: 50%;
}

.input-container span:after {
  right: 50%;
}

.input-container h4 {
  color: white; /* Change color of h4 elements */
}

.success-message {
  color: white; /* Success message color */
  font-weight: 400;
  margin-bottom: 15px;
}

.input-container.focus label {
  top: 0;
  font-size: 0.75rem;
  color: rgba(10, 60, 100, 0.979);
}

.input-container.focus span {
  color: rgba(10, 60, 100, 0.979);
}

.input-container.focus span:before,
.input-container.focus span:after {
  opacity: 1;
}

.contact-info {
  padding: 1.5rem 2.2rem;
}

.contact-info .title {
  color: #0a3c64;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 1;
  margin-bottom: 0.7rem;
  text-align: left;
}

.contact-info .text {
  color: rgba(80, 0, 80, 0.979);
  font-weight: 400;
  font-size: 1.6rem;
  margin-top: 30px;
  margin-bottom: 1.5rem;
  text-align: center;
}

.information {
  display: flex;
  align-items: center;
  margin-bottom: 1.3rem;
}

.information i {
  font-size: 1.2rem;
  color: rgba(10, 60, 100, 0.979);
}

.information p {
  margin-left: 0.7rem;
  font-size: 1rem;
  color: black;
}

.information a {
  margin-left: 0.7rem;
  font-size: 1rem;
  color: rgba(80, 0, 80, 0.979);
}

.social-media {
  margin-top: 1.5rem;
}

.social-media p {
  font-size: 1rem;
  color: rgba(80, 0, 80, 0.979);
  margin-bottom: 0.7rem;
}

.social-icons a {
  color: rgba(80, 0, 80, 0.979);
  font-size: 3rem;
  margin-right: 3rem;
}

.social-icons a:hover {
  color: rgba(10, 60, 100, 0.979);
}

.social-icons a i {
  transition: 0.3s;
}
.btn {
  padding: 0.6rem 1.3rem;
  background-color: #fff;
  border: 2px solid #fafafa;
  font-size: 0.95rem;
  color: rgba(10, 60, 100, 0.979);
  line-height: 1;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin: 0;
  width: 100%;
}

.btn:hover {
  background-color: transparent;
  color: rgba(80, 0, 80, 0.979);
}

.btn:active {
  background-color: rgba(80, 0, 80, 0.979);
  color: #fff;
}

/* Responsive Design */
@media (max-width: 768px) {
  .form {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }

  .contact-form,
  .contact-info {
    width: 80%;
  }
  .social-icons a {
    color: rgba(80, 0, 80, 0.979);
    font-size: 0.8rem;
    margin-right: 3rem;
  }
}

div.backBt {
  width: 100px;
  left: 100px;
  top: 100px;
  background-color: #f4f4f4;
  transition: all 0.4s ease;
  position: fixed;
  cursor: pointer;
}

span.lines {
  bottom: auto;
  right: auto;
  top: auto;
  left: auto;
  background-color: rgba(80, 0, 80, 0.979);
  border-radius: 10px;
  width: 100%;
  left: 0px;
  height: 2px;
  display: block;
  position: absolute;
  transition: width 0.2s ease 0.1s, left 0.2s ease, transform 0.2s ease 0.3s,
    background-color 0.2s ease;
}

span.tLines {
  top: 0px;
}

span.mLines {
  top: 13px;
  opacity: 0;
}

span.bLines {
  top: 26px;
}

.labels {
  position: absolute;
  left: 0px;
  top: 5px;
  width: 100%;
  text-align: center;
  transition: all 0.4s ease;
  font-size: 1em;
}

div.backBt:hover span.labels {
  left: 25px;
}

div.backBt:hover span.lines {
  left: -10px;
  height: 5px;
  background-color: rgba(80, 0, 80, 0.979);
}

div.backBt:hover span.tLines {
  width: 25px;
  transform: rotate(-45deg);
  left: -15px;
  top: 6px;
}

div.backBt:hover span.mLines {
  opacity: 1;
  width: 30px;
}

div.backBt:hover span.bLines {
  width: 25px;
  transform: rotate(45deg);
  left: -15px;
  top: 20px;
}

div.backBts {
  width: 50px;
  left: 50px;
  top: 200px;
  background-color: #f4f4f4;
  transition: all 0.4s ease;
  position: fixed;
  cursor: pointer;
}

span.lin {
  bottom: auto;
  right: auto;
  top: auto;
  left: auto;
  background-color: rgba(80, 0, 80, 0.979);
  border-radius: 10px;
  width: 100%;
  left: 0px;
  height: 2px;
  display: block;
  position: absolute;
  transition: width 0.2s ease 0.1s, left 0.2s ease, transform 0.2s ease 0.3s,
    background-color 0.2s ease;
}

span.tLin {
  top: 0px;
}

span.mLin {
  top: 13px;
  opacity: 0;
}

span.bLin {
  top: 26px;
}

.labe {
  position: sticky;
  left: 0px;
  top: 5px;
  width: 100%;
  text-align: center;
  transition: all 0.4s ease;
  font-size: 1em;
}

div.backBts:hover span.labe {
  left: 25px;
}

div.backBts:hover span.lin {
  left: -10px;
  height: 5px;
  background-color: rgba(80, 0, 80, 0.979);
}

div.backBts:hover span.tLin {
  width: 25px;
  transform: rotate(-45deg);
  left: -15px;
  top: 6px;
}

div.backBts:hover span.mLin {
  opacity: 1;
  width: 30px;
}

div.backBts:hover span.bLin {
  width: 25px;
  transform: rotate(45deg);
  left: -15px;
  top: 20px;
}
/* Hide .backBtns on larger screens */

div.backBts {
  display: none;
}
/* Show .backBtns and hide .backBtn on smaller screens */
@media only screen and (max-width: 480px) {
  div.backBts {
    display: flex;
  }
  title {
    margin-top: 5rem;
  }
}
.bttn {
  padding: 0.6rem 1.3rem;
  background-color: #fff;
  border: 2px solid #fafafa;
  font-size: 0.95rem;
  gap: 7px;
  color: rgba(10, 60, 100, 0.979);
  line-height: 1;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin: 0;
  width: 100%;
}

.bttn:hover {
  background-color: rgba(10, 60, 100, 0.979);
  color: rgba(10, 60, 100, 0.979);
}

@media (max-width: 768px) {
  .contact-form,
  .contact-info {
    width: 100%;
  }
  .contact-info .title {
    text-align: center;
  }
  .contact-form form {
    width: 100% !important;
    max-width: 100% !important;
  }
}

/* Mobile (max-width: 480px) */
@media (max-width: 480px) {
  .contact-form,
  .contact-info {
    width: 100%;
    padding: 1rem 1rem;
  }
  .contact-info .text {
    text-align: start;
  }
  .containers {
    padding: 1rem;
  }
}
