.products{
    background: url('./images/Product.webp') ;
    background-size: cover;
    height: 400px;
    display: flex;
    
    color: white;
    text-align: center;
    padding: 20px;
    position: relative;
}


.products-content{

    max-width: 800px;
    height: 180px;
 justify-items: left;
margin-top:11rem;
margin-right: 0%;
    border-radius: 30px;}

    .product-headings{
        font-size: 2rem;
   text-align: center;
    color:white;
    margin-top: 30px;
    font-weight: lighter;
    }
  
/* General Styles */
.details-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* 1 column layout */
  gap: 1rem; /* Gap between each item */
  padding: 1rem;
}

.details-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.details-images, .details-posters {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 1rem; 
  width: 100%;
  height: 100%;
}

.details-image, .details-poster {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  width: 100%;
  animation-duration: 1s;
}

.details-image {
  animation-name: slide-in-left;
}

.details-poster {
  animation-name: splitAndSlideFromRight; 
  animation: scrollPosters 23s linear infinite; 
}


.details-image img, .details-poster img {
  margin-top: 3rem;
  width: 100%;
  height:80%;
  transition: transform 3s ease;
}

.details-image a, .details-poster a {

 text-align: center;
 
  font-size: 2rem;
  color: #500050;
  text-decoration: none;
  font-weight: bold;
}

.details-image:hover img, .details-poster:hover img {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

/* Scrollable Flow for Posters */
.details-posters {
  display: flex;
  overflow-x: auto;
  white-space: nowrap; /* Use nowrap for horizontal scrolling */
  scroll-behavior: smooth;
  padding: 1rem;
}

.details-poster {
  flex: 0 0 auto;
  width: 350px; /* Set a fixed width for each poster */
  height: auto;
  margin-right: 1rem;
  transition: transform 0.3s ease;
}

.details-posters:hover .details-poster {
  animation-play-state: paused; /* Pause the flow on hover */
}
@keyframes slideInFromLeft {
  0% {
      transform: translateX(-100%);
      opacity: 0;
  }
  25% {
      transform: translateX(-50%);
      opacity: 0.5;
  }
  50% {
      transform: translateX(0%);
      opacity: 1;
  }
  75% {
      transform: translateX(0%);
      opacity: 1;
  }
  100% {
      transform: translateX(0%);
      opacity: 1;
  }
}

.details-image {
  animation: slideInFromLeft 1s ease-out;
}
.aa{
  font-size: 3rem;
  color: black;
  text-align: center;
}
@keyframes splitAndSlideFromRight {
  0% {
      transform: translateX(100%);
      opacity: 0;
  }
  25% {
      transform: translateX(50%);
      opacity: 0.5;
  }
  50% {
      transform: translateX(0%);
      opacity: 1;
  }
  75% {
      transform: translateX(0%);
      opacity: 1;
  }
  100% {
      transform: translateX(0%);
      opacity: 1;
  }
}

/* Additional Styling for Sidebar */
.sidebarsss {
  background-color: white;
  margin-top: 2rem;
  border-radius: 8px;
}
.sideulss {
  list-style-type: none;
  padding: 1rem;
  background-color: #0a3c64;
  display: flex; /* Use flexbox for horizontal layout */
  justify-content: center; /* Center items horizontally, adjust as needed */
  flex-wrap: wrap; /* Wrap items if they overflow */
}

.sideli {
  margin: 0.6rem;
  text-align: center;
  cursor: pointer;
  font-weight: lighter;
  background-color: #0a3c64;
  color: white;
  padding: 0.5rem 1rem; /* Adjust padding to your preference */
  border-radius: 13px;
  transition: background-color 0.3s ease;
}


.sideli:hover {
  background-color: #500050;
}


@media only screen and (max-width: 458px){
    .sideulss {
  list-style-type: none;
  padding: 1rem;
  background-color: #0a3c64;
  display:block; 
  justify-content: center; 
  flex-wrap: wrap; 
}
.products{
  background: url('./images/Product.webp') ;
  background-size: cover;
  height: 300px;
  display: flex;
  
  color: white;
  text-align: center;
 
}

}
@keyframes scrollPosters {
  0% {
    transform: translateX(100%); /* Start from the right */
  }
  100% {
    transform: translateX(-100%); /* Move to the left */
  }
}

/* Pause scrolling on hover */
.details-posters:hover {
  animation-play-state: paused;
}



@media only screen and (max-width: 768px) {



  .products {
    height: auto; /* Adjust height for mobile */
  }

  .details-images, .details-posters {
    grid-template-columns: repeat(1, 1fr); /* 1 column layout */
  }

  .details-poster {
    width: 100%; 
    margin-right: 0;
  }

  .products-content {
    max-width: 100%; 
    margin-top: 5rem;
  }
}

.h6{
  color: black;
  font-size:xx-large;
}