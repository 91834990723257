

  .bannerss {
    background: url('./images/AboutWall.jpg') ;
    background-size: cover;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    padding: 20px;
    position: relative;
  }
  
  .bannerss-contents {

    max-width: 800px;
    height: 180px;
    position:absolute ;
    bottom: 100px;
    left: 2px;
    border-radius: 30px;
  }
  
  .bannerss-headings {
    font-size: 2rem;
   text-align: center;
    color:white;
    margin-top: 30px;
    font-weight: lighter;
  }
  
  
  
  
  .about {
    display: flex;
    justify-content: center;
    padding: 40px;
  }
  
  .about-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    width: 100%;
    max-width: 1200px;
  }
  
  .about-image {
    display: flex;
    align-items: center;
    justify-content: center;
    animation: slideInFromLeft 1s ease-out forwards, swing 2s ease-in-out infinite;
    overflow: hidden; 

    transform-origin: center -20px;
    float:left;
    flex: 1;
    box-shadow: 5px 5px 10px rgba(0,0,0,0.5);}
    
  .about-image img {
    width: 100%;
    height: auto;
    object-fit: cover; 
    border: 5px solid #f8f8f8;
    display: block;
   /* Ensures the image covers the area without distortion */
  }
  .about-image:after { 
     content: '';
    position: absolute;  
    width: 20px; height: 25px;  
    border: 1px solid white;
    top: -20px; left: 80%;
    z-index: 0;
    border-bottom: none;
    border-right: none;
    transform: rotate(45deg);
  }


  .about-image :before{
    content: '';
    position: absolute;
    width: 5px; height: 5px;
    top: -24px;left: 54%;
    z-index: 5;
    border-radius: 50% 50%;
    background: #000;
}

@keyframes  about-image{
  0% { transform: rotate(3deg); }
  100% { transform: rotate(-3deg); }
}
  .about-content {
    padding: 10px;
   
  }
  
  
  
  .sdss {
    margin: 0;
    font-size: 1rem;
    text-align: justify;
    color: rgba(80, 0, 80, 0.979);
    opacity: 0; 
    transform: translateY(100%); 
  }
  
  .hhss {
    margin-bottom: 10px;
    color: rgba(10, 60, 100, 0.979);
    text-align: center;
    opacity: 0;
    transform: translateX(100%); 
  }
  
  .sd {
    margin: 0;
    font-size: 1rem;
    text-align: justify;
    color: rgba(80, 0, 80, 0.979);
    opacity: 0; 
    transform: translateX(-100%);
  }
  
  .hh {
    margin-bottom: 10px;
    color: rgba(10, 60, 100, 0.979);
    text-align: center;
    opacity: 0;    transform: translateX(100%); 
  }
  
  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes swing {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(10deg);
    }
    40% {
      transform: rotate(-8deg);
    }
    60% {
      transform: rotate(5deg);
    }
    80% {
      transform: rotate(-5deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

.values {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.values-container {
  display: flex;
  width: 100%;
  max-width: 1200px;
}

.values-content {
  flex: 2;
  padding-right: 50px;
  
}

.values-image {
  flex: 1;
}

.values-change {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.values-change button {
  background-color: rgba(10, 60, 100, 0.979);
  color: white;
  border: none;
  border-radius: 20px;
  width: 180px;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 0px;
}

.values-change button:hover {
  background-color: rgba(60, 0, 60, 0.979);
}

.content-values {
  display: flex;
  flex-wrap: wrap;
}

.values-icons {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.icon-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.icon-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(80, 0, 80, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: rgba(80, 0, 80, 0.979);
}
.hs{
  color:  rgba(10, 60, 100, 0.979);
  font-size: 2.3rem;
  margin-top: 40px;
  text-align: center;
}
.sd{
  color: rgba(80, 0, 80, 0.979) ;
  font-size: 1rem;
  text-align: justify;
  margin-top: 3px;


}
.content-values h3{
  color: rgba(80, 0, 80, 0.979) ;
  font-size: 1.5rem;


}

.content-values p{
  color:  rgba(10, 60, 100, 0.979); ;
  font-size: 1.3rem;
  margin-top: 5px;


}/* Tablets */
@media only screen and (min-width: 768px)  {
 

  .banner-contents {
    max-width: 700px;
    height: 160px;
  }
  h3{
    color:  rgba(10, 60, 100, 0.979);
    font-size: 2.3rem;
    margin-top: 40px;
    text-align: center;
  }
  .about-container {
    grid-template-columns: 1fr 1fr;
  }
  
  /* Values Section Adjustments */

}

/* Laptops */
@media only screen and (max-width: 1440px) {
  

  .banner-contents {
    max-width: 800px;
    height: 180px;
  }

  .about-container {
    grid-template-columns: 1fr 1fr;
  }
  .values-content {
    flex: 1 2;
    margin-left: 4rem;
    padding-right: 3rem;
}
}
@media only screen and (max-width: 480px) {
  .about-container {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .about-image, .about-content {
    max-width: 100%;

    box-shadow: none;
  }

  .about-content p {
    font-size: 0.9rem;
    text-align:justify;

  }
}

@media only screen and (max-width: 480px) {
  .values-container {
    flex-direction: column;
  }

  .values-content, .values-image {
    width: 100%;
    padding-right: 0;
    margin: 0;
  }

  .values-change {
    flex-direction: column;
  }

  .values-change button {
    width: 100%;
    margin: 5px 0;
  }

  .values-icons {
    flex-direction: column;
  }

  .icon-item {
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
  }
}
